.textarea-wrapper {
  background-color: white;
}

.textarea {
  transition: all linear 100ms;
  z-index: 2;
  font-weight: 400;
}

.textarea:focus {
  padding-left: 15px;
  padding-top: 15px;
}

.textarea:focus ~ label,
.textarea:focus:not(:placeholder-shown) ~ label,
.textarea:-webkit-autofill ~ label {
  transition: all linear 100ms;
  top: -12px;
  font-size: 14px;
  z-index: 3;
}

.textarea:not(:placeholder-shown) ~ label {
  transition: all linear 100ms;
  top: -12px;
  font-size: 14px;
  z-index: 3;
  @apply text-black;
}

.textarea-label {
  font-weight: 300;
  position: absolute;
  left: 12px;
  top: 12px;
  transition: all 0.1s ease-out;
  background-color: inherit;
  z-index: 1;
  padding: 0 4px;
}

.textarea:-webkit-autofill,
.textarea:-webkit-autofill:hover,
.textarea:-webkit-autofill:focus,
.textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
