.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper-opened {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-out;
}

.modal {
  top: 100%;
  width: 800px;
  visibility: hidden;
  position: relative;
  transition: all 0.2s ease-in;
  opacity: 0;
  height: fit-content;
  max-height: calc(100vh - 128px);
  outline: none;
  border-radius: 12px;
  cursor: default !important;
  @apply bg-white;
}

.modal-small {
  width: 100%;
  max-width: 600px;
}

.modal-opened {
  top: 0;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-out;
}

.content {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 24px 24px 0 24px;
}

.modal-body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 24px 0 48px 0;
}

.modal-footer {
  padding: 0 24px 24px 24px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overflow {
  overflow: visible;
}
