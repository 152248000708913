main {
  min-height: calc(100vh - 160px);
}

main section {
  margin-bottom: 3rem;
}

main section:first-child {
  margin-top: 3rem;
}
